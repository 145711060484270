import { createActionCreator, createReducer } from 'deox';
import { combineReducers } from 'redux';

import { addOrUpdMessage } from './messageNotify';
import { RootState as RootStateType } from './root';
import { ImageMetadata } from './types/metadataTypes';

/**
 * Types/Interfaces
 */
// {
//   'order_date': params.get('order_date') or order_date,
//   'metadata_ids': metadata_ids,
//   'user_uuid': request.user.uuid,
//   'system_segment': "internal" if system_segment == 1 else "external",
//   'add_coverage_shape': False if len(metadata_ids) == 1 else True,
//   'add_quicklook': True,
//   'add_metadata_profile': True
// }
export interface MetadataDownloadTaskCreate {
  // metadataIds: number[];
  metadataIds: Array<ImageMetadata['metadataId']>;
}
export interface MetadataDownloadTask extends MetadataDownloadTaskCreate {
  taskUuid?: string; // get after create task
  orderDate: string;
  // metadataIds: number[];
  userUuid: string;
  systemSegment: 'internal' | 'external';
  addCoverageShape: boolean;
  addQuicklook: boolean;
  addMetadataProfile: boolean;
}

/**
 * Actions
 */
export const createTask = {
  request: createActionCreator(
    'METADATA_DOWNLOAD/CREATE_TASK_REQUEST',
    resolve => (params: MetadataDownloadTaskCreate) => resolve(params)
  ),
  success: createActionCreator(
    'METADATA_DOWNLOAD/CREATE_TASK_SUCCESS',
    resolve => (data: MetadataDownloadTask) => resolve(data)
  ),
  failure: createActionCreator('METADATA_DOWNLOAD/CREATE_TASK_FAILURE', resolve => error =>
    resolve(error)
  ),
};

/**
 * Reducers and state
 */
export const tasksDefaultState: MetadataDownloadTask[] = [
  /* {
    taskUuid: 'wqewqe',
    metadataIds: [123, 32423],
    orderDate: '2020-05-23T16:00:00', // formatISO(new Date()),
    userUuid: 'dsadwqe',
    systemSegment: 'external', // 'internal' | 'external';
    addCoverageShape: true, // TODO - metadata_ids.length > 1 ? true : false,
    addQuicklook: true,
    addMetadataProfile: true,
  }, */
];
export const tasksReducer = createReducer(tasksDefaultState, handleAction => [
  handleAction(createTask.success, (state, { payload }) => [...state, payload]),
  handleAction(addOrUpdMessage, (state, { payload }) => {
    const taskUuid = payload.extendedData.taskUuid;
    const taskIndex = state.findIndex(task => task.taskUuid === taskUuid);
    if (taskIndex > -1) {
      const result = [...state];
      result.splice(taskIndex, 1);
      return result;
    }
    return state;
  }),
]);

// metadataRootReducer
export default combineReducers({
  tasks: tasksReducer,
});

/**
 * Selectors
 */
export const getTasks = (state: RootStateType) => state.metadataDownload.tasks;
