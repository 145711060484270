import { Trans } from '@lingui/macro';
import { i18nMark } from '@lingui/react';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import CustomScrollBarContainer from '@geobank/components/src/forms/CustomScrollbarContainer/CustomScrollBarContainer';
import SearchParamsCompact from './SearchParamsCompact';

import { RootState } from 'ducks';
import { getUser } from 'ducks/authIAM';
import { notifyActions } from 'ducks/message';
import {
  getActiveImageContours,
  getActiveImagePreviews,
  getCheckedImages,
  getIsFetching,
  getSelectedMetadataRoute,
  getTotalImages,
  ImageMetadata,
  removeMetadataRoute,
  searchImages,
  selectMetadataRoute,
  toggleCheckImage,
  toggleImageContour,
  toggleImageFullRes,
  toggleImagePreview,
} from 'ducks/metadata';
import { createTask } from 'ducks/metadataDownload';
import { getCurrentCartImages } from 'ducks/order';
import { ImageMetadataDetail, SearchParams } from 'ducks/types/metadataTypes';
import { copyTextToClipboard } from 'utils/clipboard';
import { isEmptyValue } from 'utils/values';

import * as orderTypes from 'ducks/types/orderTypes';
import SearchResultHeaderActions from './SearchResultHeaderActions';
import { CHECKED, INDETERMINATE, UNCHECKED } from './SearchResultItem/CheckBox/Checkbox';
import SearchResultItem from './SearchResultItem/SearchResultItem';
import SearchResultStatusBar from './SearchResultStatusBar';

import styles from './SearchResult.module.scss';

interface SearchResultProps {
  activeImageCartSearch: string[];
  activeImageContours: string[];
  activeImageFullResMs: string[];
  activeImageFullResPan: string[];
  activeImagePreviews: string[];
  checkedImages: string[];
  createTaskRequest: typeof createTask.request;
  currentCartImages: orderTypes.CartImage[];
  isFetchingImages: boolean;
  pushMessage: typeof notifyActions.push;
  removeMetadataRoute: typeof removeMetadataRoute;
  results: ImageMetadataDetail[];
  searchMetadataRequest: typeof searchImages.request;
  searchParams: SearchParams;
  selectedMetadataRoute: string | null;
  selectMetadataRoute: typeof selectMetadataRoute;
  swipeImages: string[];
  toggleCheckImage: typeof toggleCheckImage;
  toggleImageContour: typeof toggleImageContour.request;
  toggleImageFullRes: typeof toggleImageFullRes.request;
  toggleImagePreview: typeof toggleImagePreview.request;
  totalImages: number;
  user: ReturnType<typeof getUser>;
  onBack: () => void;
  onAddToCartImage: (imageId: ImageMetadata) => void;
}
interface SearchResultState {
  currentExpandedMetadataId: ImageMetadata['identifier'];
  isResultsHidden: boolean;
}

export class SearchResult extends PureComponent<SearchResultProps, SearchResultState> {
  public state = {
    currentExpandedMetadataId: '',
    isResultsHidden: false,
  };

  private readonly listRef: React.RefObject<CustomScrollBarContainer>;

  constructor(props: any) {
    super(props);
    this.listRef = React.createRef();
  }

  public getCheckedImages = () => {
    const checkedImages: ImageMetadata[] = [];
    // TODO - сохранить сортировку (порядок) снимков
    this.props.results.forEach(md => {
      if (this.props.checkedImages.indexOf(md.identifier) > -1) {
        checkedImages.push(md);
      }
    });
    return checkedImages;
  };

  public handleAddToCart = (md: ImageMetadata) => {
    this.props.onAddToCartImage(md);
  };

  public handleRemoveRouteFromResults = (md: ImageMetadata) => {
    this.props.removeMetadataRoute(md.identifier);
  };

  public handleSelectRoute = (md: ImageMetadata) => {
    this.props.selectMetadataRoute(md.identifier);
    if (this.props.searchParams.sourceType !== 'esmdp2') {
      this.props.toggleImagePreview([md.identifier]);
    }
  };

  // TODO - можно передавать списком выделенные снимки (checked)
  // или передавать пустой аргумент (undefined/null) и получать список в сагах по селектору
  public handleToggleContour = (md?: ImageMetadata) => {
    md !== undefined
      ? this.props.toggleImageContour([md.identifier])
      : this.props.toggleImageContour(this.props.checkedImages);
  };

  public handleTogglePreview = (md?: ImageMetadata) => {
    if (md !== undefined) {
      if (!md.identifier.startsWith('APOI')) {
        this.props.toggleImagePreview([md.identifier]);
      }
    } else {
      this.props.toggleImagePreview(this.props.checkedImages);
    }
  };

  public handleToggleFullImage = (ids?: string[]) => {
    if (ids) {
      this.props.toggleImageFullRes(ids);
    } else {
      this.props.toggleImageFullRes(this.props.checkedImages);
    }
  };

  public handleToggleCheckImage = (md: ImageMetadata) => {
    this.props.toggleCheckImage(md.identifier);
  };

  public handleCheckAll = (checked: boolean) => {
    checked
      ? this.props.toggleCheckImage(this.props.results.map(md => md.identifier))
      : this.props.toggleCheckImage([]);
  };

  public handleMetadataDownload = (md?: ImageMetadata) => {
    const getIdForMdDownloadTask = (_md: ImageMetadata) => {
      return _md.identifier.startsWith('BBP.') || !_md.hasOwnProperty('metadataId')
        ? _md.identifier
        : _md.metadataId;
    };

    const imageIds =
      md !== undefined
        ? [String(md.metadataId)]
        : this.props.results
            .filter(item => this.props.checkedImages.includes(item.identifier))
            .map(item => String(item.metadataId));
    const identifiers = this.props.results
      .filter(_md => imageIds.indexOf(String(_md.metadataId)) > -1)
      .map(_md => getIdForMdDownloadTask(_md));

    this.props.createTaskRequest({ metadataIds: identifiers });
  };

  public handleCopyToClipboard = (text: string) => {
    copyTextToClipboard(text)
      .then(() => {
        this.props.pushMessage({
          message: i18nMark('Идентификатор скопирован в буфер обмена'),
          place: 'bc',
        });
      })
      .catch(() => {
        this.props.pushMessage({
          message: i18nMark('Ошибка при копировании в буфер обмена'),
          place: 'bc',
        });
      });
  };

  // TODO - rename to 'preview' ?

  /* public handleGroupImgClick = (event: React.MouseEvent<HTMLInputElement>) => {
    // event.preventDefault();
    event.stopPropagation();
    alert('img group click');
    // const el = event.target;
    // console.log(el);
    // console.log(event);
  }; */

  // private handleAccordionToggle = (id: number, flag: boolean) => {
  public handleAccordionToggle = (md: ImageMetadata, flag?: boolean) => {
    if (flag === true) {
      this.setState({ currentExpandedMetadataId: md.identifier });
    } else {
      this.setState({ currentExpandedMetadataId: '' });
    }
  };

  public handleLoadMore = () => {
    const searchParams = this.props.searchParams;
    this.props.searchMetadataRequest({
      ...searchParams,
      offset: searchParams.offset! + searchParams.limit!,
      // TODO? scroll to new items (callback)
    });
  };

  public componentDidUpdate(prevProps: SearchResultProps) {
    if (this.props.selectedMetadataRoute !== prevProps.selectedMetadataRoute) {
      const el = document.getElementById(`resultsItem-${this.props.selectedMetadataRoute}`);
      if (!el) {
        return;
      }
      const anchorOffset = el.offsetTop;
      // @ts-ignore
      const listScrollTop = this.listRef.current.scrollRef.current.getScrollTop();
      // @ts-ignore
      const clientHeight = this.listRef.current.scrollRef.current.getClientHeight() - 20;
      if (el.offsetTop - listScrollTop > clientHeight || listScrollTop > el.offsetTop) {
        this.listRef.current!.scrollTop(anchorOffset);
      }
    }
  }

  public render() {
    const { currentExpandedMetadataId } = this.state;
    const {
      // activeImageCartSearch,
      activeImageContours,
      activeImagePreviews,
      checkedImages,
      currentCartImages,
      isFetchingImages,
      results,
      searchMetadataRequest,
      searchParams,
      selectedMetadataRoute,
      // swipeImages,
      totalImages,
      onBack,
      // user,
    } = this.props;

    // const activeImageCartItems: ImageMetadataDetail[] = results.filter(item =>
    //   activeImageCartSearch.includes(item.identifier)
    // );

    let pHeight = -44;
    for (const [key, value] of Object.entries(searchParams)) {
      if (
        !isEmptyValue(value) &&
        [
          'illuminationElevationAngleMin',
          'illuminationElevationAngleMax',
          'nadirTiltAngleMin',
          'nadirTiltAngleMax',
        ].indexOf(key) === -1
      ) {
        pHeight += 22;
      }
    }

    const currentCartImagesIds = currentCartImages.map(cartImage => cartImage.metadataIdentifier);

    return (
      <>
        <SearchParamsCompact
          params={searchParams}
          onEditParams={onBack}
          searchMetadataRequest={searchMetadataRequest}
          total={totalImages}
        />

        <div className={styles.listHeader}>
          <SearchResultHeaderActions
            checkValue={
              checkedImages.length > 0
                ? checkedImages.length < results.length
                  ? INDETERMINATE
                  : CHECKED
                : UNCHECKED
            }
            onCheckAll={this.handleCheckAll}
            onToggleContour={this.handleToggleContour}
            onTogglePreview={this.handleTogglePreview}
            onToggleFullImage={this.handleToggleFullImage}
            onMetadataDownload={this.handleMetadataDownload}
          />
        </div>
        <div className={styles.root}>
          <CustomScrollBarContainer
            ref={this.listRef}
            heightMin="150px"
            heightMax={`calc(100vh - 280px + 20px - ${pHeight}px)`}
            color="#CEEAFB"
          >
            {results.length === 0 && (
              <div className={styles.emptyResultMessage}>
                <Trans>По заданным условиям нет снимков</Trans>
              </div>
            )}
            {results.map((item, index) => (
              <React.Fragment key={item.identifier}>
                {index !== 0 && <div className={styles.horizontalLine2} />}
                <div
                  id={`resultsItem-${item.identifier}`}
                  // className={
                  //   swipeImages.includes(item.identifier) ? styles.selectedSwipe : undefined
                  // }
                >
                  <SearchResultItem
                    data={item}
                    isActive={item.identifier === selectedMetadataRoute}
                    isChecked={checkedImages.indexOf(item.identifier) !== -1}
                    isOpen={currentExpandedMetadataId === item.identifier}
                    isInCart={
                      currentCartImagesIds.findIndex(value => value.startsWith(item.identifier)) >
                      -1
                    }
                    isContourActive={activeImageContours.indexOf(item.identifier) > -1}
                    isPreviewActive={activeImagePreviews.indexOf(item.identifier) > -1}
                    // isFreeUser={!user.haveToPay()}
                    actions={{
                      onActivate: this.handleSelectRoute.bind(this, item),
                      onAddToCart: this.handleAddToCart,
                      onCopyToClipboard: this.handleCopyToClipboard,
                      onMetadataDownload: this.handleMetadataDownload,
                      onRemove: this.handleRemoveRouteFromResults,
                      onToggleCheck: this.handleToggleCheckImage,
                      onToggleContour: this.handleToggleContour,
                      onToggleFullImage: this.handleToggleFullImage,
                      onToggleOpen: this.handleAccordionToggle,
                      onTogglePreview: this.handleTogglePreview,
                    }}
                  />
                </div>
              </React.Fragment>
            ))}
          </CustomScrollBarContainer>
          <SearchResultStatusBar
            limit={searchParams.limit!}
            offset={searchParams.offset!}
            isFetchingImages={isFetchingImages}
            onLoadMore={this.handleLoadMore}
          />
        </div>
      </>
    );
  }
}

export default connect(
  (state: RootState) => ({
    // activeImageCartSearch: getActiveImageCartSearch(state),
    activeImageContours: getActiveImageContours(state),
    activeImagePreviews: getActiveImagePreviews(state),
    checkedImages: getCheckedImages(state),
    currentCartImages: getCurrentCartImages(state),
    isFetchingImages: getIsFetching(state),
    selectedMetadataRoute: getSelectedMetadataRoute(state),
    totalImages: getTotalImages(state),
    user: getUser(state),
  }),
  {
    createTaskRequest: createTask.request,
    pushMessage: notifyActions.push,
    removeMetadataRoute,
    searchMetadataRequest: searchImages.request,
    selectMetadataRoute,
    toggleCheckImage,
    toggleImageContour: toggleImageContour.request,
    toggleImageFullRes: toggleImageFullRes.request,
    toggleImagePreview: toggleImagePreview.request,
  },
  null,
  { forwardRef: true }
)(SearchResult);
